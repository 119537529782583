import apiClient from '@/_shared/services/apiClient';
import IInteraction from '@/timeline/types/IInteraction';
import { ServiceSummary, ServiceSummaryParams } from '@/timeline/types/ServiceSummary';
import useServiceFiltering from '@/_shared/store/serviceFiltering';
import useCarePlanStore from '@/careplan/store/carePlanStore';
import { storeToRefs } from 'pinia';
import { Parameter } from '../types/Parameter';

interface InteractionApiResponse {
  interaction: IInteraction
}

interface InteractionsApiResponse {
  interactions: IInteraction[]
}

interface InteractionCalculateApiResponse {
  computedParameters: Parameter[]
}

export const fetchInteraction = async (id: number): Promise<IInteraction | undefined> => {
  const url = `/api/v2/interactions/${id}?extended=true`; // TODO use other endpoint for now
  try {
    const response = (await apiClient.get<InteractionApiResponse>(url));

    return response.interaction;
  } catch (errorsResponse) {
    // TODO deal with errors
    // interaction.errors = [];
    // interaction.errors = setInteractionErrors(interaction, errorsResponse);
    // return interaction;
    return undefined;
  }
};

export const newInteraction = async (clientId: number, serviceId: number): Promise<IInteraction | undefined> => {
  const serviceFilteringStore = useServiceFiltering();
  const { currentInstance } = serviceFilteringStore;
  const { consentCarePlanReportId, consentCarePlanServiceId } = storeToRefs(useCarePlanStore());
  let url = `/api/v2/clients/${clientId}/interactions/new?interaction[service_id]=${serviceId}`; // TODO use other endpoint for now
  if (currentInstance.instanceId) {
    url += `&interaction[nourish_instance_id]=${currentInstance.instanceId}`;
  }
  if (consentCarePlanReportId.value && consentCarePlanServiceId.value as number === +serviceId) {
    url += `&report_id=${consentCarePlanReportId.value}`;
  }
  try {
    const response = (await apiClient.get<InteractionApiResponse>(url));
    return response.interaction;
  } catch (errorsResponse) {
    // TODO deal with errors
    // interaction.errors = [];
    // interaction.errors = setInteractionErrors(interaction, errorsResponse);
    // return interaction;
    return undefined;
  }
};

const removeSubParameters = (parameters: Parameter[]) => parameters.filter((p) => !p.id.toString().includes('.'));

const getSubParameters = (parameters: Parameter[]) => parameters.filter((p) => p.id.toString().includes('.'));

const addSubParameters = (parameters: Parameter[], subParameters: Parameter[]) => [...parameters, ...subParameters];

// TODO pass interaction ref and an errors ref directly, so these can be updated without returning
export const createInteraction = async (interaction: IInteraction) => {
  const subParameters = getSubParameters(interaction.parameters);
  if (subParameters.length > 0) {
    interaction.parameters = removeSubParameters(interaction.parameters);
  }
  let url = `/api/v2/clients/${interaction.clientId}/interactions?extended=true`;
  const { consentCarePlanReportId, consentCarePlanServiceId } = storeToRefs(useCarePlanStore());
  if (consentCarePlanReportId.value && consentCarePlanServiceId.value as number === +interaction.serviceId) {
    url += `&report_id=${consentCarePlanReportId.value}`;
  }
  try {
    const response = (await apiClient.post<InteractionApiResponse>(url, { interaction }));
    interaction.id = response.interaction.id;
    return response.interaction;
  } catch (errorsResponse) {
    interaction.errors = setInteractionErrors(interaction, errorsResponse);
    interaction.parameters = addSubParameters(interaction.parameters, subParameters);
    return interaction;
  }
};

export const updateInteraction = async (interaction: IInteraction) => {
  const subParameters = getSubParameters(interaction.parameters);
  if (subParameters.length > 0) {
    interaction.parameters = removeSubParameters(interaction.parameters);
  }
  const url = `/api/v2/clients/${interaction.clientId}/interactions/${interaction.id}?extended=true`;
  try {
    const response = (await apiClient.put<InteractionApiResponse>(url, { interaction }));
    return response.interaction;
  } catch (errorsResponse) {
    interaction.errors = setInteractionErrors(interaction, errorsResponse);
    interaction.parameters = addSubParameters(interaction.parameters, subParameters);
    return interaction;
  }
};

export const quickCloseInteraction = async (interaction: IInteraction, closerId: number) => {
  const now = new Date().toString();
  interaction.closedAt = now;
  interaction.finishAt = now;
  interaction.state = 'closed';
  interaction.closerId = closerId;
  if (interaction.responsiblePersonIds) {
    interaction.responsiblePersonIds.push(closerId);
  } else {
    interaction.responsiblePersonIds = [closerId];
  }
  try {
    return updateInteraction(interaction);
  } catch (errorsResponse) {
    interaction.errors = setInteractionErrors(interaction, errorsResponse);
    return interaction;
  }
};

export const recalculateComputedParameters = async (interaction: IInteraction, hasActions: boolean) => {
  let url = '/api/v2/interactions/calculate?only_calculate=true';
  if (hasActions) {
    url += '&has_actions=true';
  }
  const interactionToCalculate = { ...interaction };
  const subParameters = getSubParameters(interaction.parameters);
  if (subParameters.length > 0) {
    interactionToCalculate.parameters = removeSubParameters(interaction.parameters);
  }
  interaction.parameters?.forEach((parameter: Parameter) => delete parameter.value);
  interactionToCalculate.parameters?.forEach((parameter: Parameter) => delete parameter.value);
  if (interaction.finishAt === null) { interaction.finishAt = new Date().toString(); }
  try {
    return await apiClient.post<InteractionCalculateApiResponse>(url, { interaction: interactionToCalculate });
  } catch (errorsResponse) {
    interaction.errors = setInteractionErrors(interaction, errorsResponse);
    return { computedParameters: interaction.computedParameters };
  }
};

export const runInteractionActions = async (id: number, reallyRun = false) => {
  let url = `/api/v2/interactions/${id}/run_protocols`;
  if (reallyRun) {
    url += '?really_run=true';
  }
  try {
    return await apiClient.post<string>(url);
  } catch (errorsResponse) {
    return undefined;
  }
};

type ErrorResponse = {
  response: {
    data: unknown[] | Record<string, unknown>
  }
}

const setInteractionErrors = (interaction: IInteraction, errorsResponse: ErrorResponse): Record<string, string[]> => {
  let errors: unknown[] | Record<string, unknown> = [];
  if (errorsResponse.response && errorsResponse.response.data) {
    errors = errorsResponse.response.data;
  }
  if (errors instanceof Array) {
    return { error: errors } as Record<string, string[]>;
  }
  if (errors instanceof Object && errors.errors instanceof Object) {
    return errors.errors as Record<string, string[]>;
  }
  return {};
};

export const getInteractions = async (
  query: object,
  additionalParams: object,
  closedOnly = true,
  extended = true,
): Promise<IInteraction[]> => {
  const url = `/api/v2/organisation_units/${window.currentOrganisationUnit.id}/interactions/query?extended=${extended}`;
  if (closedOnly) {
    query = Object.assign(query, { state: 'closed' });
  }
  try {
    const data = { query, ...additionalParams };
    const response = await apiClient.post<InteractionsApiResponse>(url, data);
    return response.interactions;
  } catch (errorsResponse) {
    console.error(errorsResponse);
    return [];
  }
};

export const getChildInteractions = async (
  query: object,
  additionalParams: object,
): Promise<IInteraction[]> => {
  const url = `/api/v2/organisation_units/${window.currentOrganisationUnit.id}/interactions/query?extended=true`;
  query = Object.assign(query, { only_protocol_children: true });
  try {
    const data = { query, ...additionalParams };
    const response = await apiClient.post<InteractionsApiResponse>(url, data);
    return response.interactions;
  } catch (errorsResponse) {
    console.error(errorsResponse);
    return [];
  }
};

export const getLastInteraction = async (clientId: number, serviceId: number): Promise<IInteraction> => {
  const interaction = await getInteractions(
    {
      clientId,
      serviceId,
      state: 'closed',
      limit: 1,
    },
    { sort: '-finish_at' },
  );
  // Todo find better return
  return <IInteraction>interaction[0];
};
interface InteractionVersionsApiResponse {
  versions: IInteraction[]
}

export const fetchChildInteractions = async (clientId: number, protocolParentId: number): Promise<IInteraction[]> => {
  const interactions = await getChildInteractions(
    {
      clientId,
      protocolParentId,
      state: 'closed',
    },
    { sort: '-finish_at' },
  );

  return <IInteraction[]>interactions;
};

export const getInteractionsPastVersions = async (
  clientId: number,
  interactionId: number,
): Promise<IInteraction[]> => {
  const url = `/api/v2/clients/${clientId}/interactions/${interactionId}/versions`;
  try {
    const response = await apiClient.get<InteractionVersionsApiResponse>(url);
    return response.versions;
  } catch (errorsResponse) {
    console.error(errorsResponse);
    return [];
  }
};

interface ServiceSummaryApiResponse {
  serviceSummaries: ServiceSummary[]
}

export const fetchServiceSummary = async (params: ServiceSummaryParams) => {
  const url = '/api/v2/service_summaries';

  if (!params.subscribedServiceId && !params.providedServiceId && !params.serviceId) {
    return [];
  }
  try {
    const serviceSummaryResponse = await apiClient.get<ServiceSummaryApiResponse>(url, { params });

    // this needs to be done on the backend at a later date
    const calculations = serviceSummaryResponse.serviceSummaries.map((e: ServiceSummary) => {
      e.name = e.name?.replace(/^Total /, '');
      return e;
    });

    return calculations;
  } catch (error) {
    return [];
  }
};

export const fetchForClient = async (clientId: number, params: object) => {
  try {
    const response = await apiClient.get<InteractionsApiResponse>(`/api/v2/clients/${clientId}/interactions`, { params });
    return response.interactions;
  } catch (error) {
    return [];
  }
};

export const fetchForCarer = async (carerId: number, params: object) => {
  try {
    const response = await apiClient.get<InteractionsApiResponse>(`/api/v2/carers/${carerId}/interactions`, { params });
    return response.interactions;
  } catch (error) {
    return [];
  }
};

export const searchInteractions = async (query: Record<string, unknown>) => {
  query.extended = true;
  query.exclude_keys = ['default_notes', 'is_prefillable', 'needs_start_at', 'quick_closeable', 'supporting_documents', 'care_plan_documents'];
  try {
    const response = await apiClient.post<InteractionsApiResponse>('/api/v2/interactions/search', query);
    return response.interactions;
  } catch (error) {
    return [];
  }
};

export const fetchLatestCareplanAgreement = async (clientId: number) => {
  try {
    const response = await apiClient.get<InteractionApiResponse>(`/api/v2/clients/${clientId}/interactions/latest_care_plan_agreement`);
    console.log(response);
    return response.interaction;
  } catch (error) {
    return null;
  }
};

export const hasAccessToConsentSignature = async (clientId: number) => {
  try {
    const response = await apiClient.get<boolean>(`/api/v2/clients/${clientId}/interactions/has_access_to_consent_signature`);
    return response;
  } catch (error) {
    return null;
  }
};
