import use from '@/_shared/compositionApi';
import {
  LocationQuery,
  RouteLocationNormalizedLoaded,
  useRoute,
} from 'vue-router';
import { computed } from 'vue';
import useUserStore from '@/_shared/store/user';
import { TopBarLink, filterLinksWithRouteNames } from '@/_shared/types/baseTopBarLink';

export default function getDynamicLinks(clientId: number, forFamilyPortal: boolean, route :RouteLocationNormalizedLoaded, forMobileView = false) {
  const routePrefix = forFamilyPortal ? 'portalClient' : 'client';

  const activateTimeLine = computed(() => {
    const currentRouteName = useRoute()?.name;
    if (currentRouteName && timelineNestedRoute.includes(currentRouteName.toString())) return true;
    return false;
  });
  const { translate } = use.helpers();
  const { clientStore } = use.store.clients();
  const clientType = computed(() => clientStore.byId(clientId)?.clientType || '');
  const { userHasPermission, userCan } = useUserStore();
  const defaultLinks: TopBarLink[] = useUserStore().getFeatureToggle('covidTracker') ? [] : getDefaultLinks();
  const state = clientStore.currentOrgUnitState(+clientId);
  // TODO / TECH DEBT the hiddenLinksByState is also in vue/src/clients/components/ClientTopBar.vue
  // we need to maintain it in both places until we turn mobile vue into VUE.  Make sure we update it in both places
  const hiddenLinksByState: { [key: string]: string[]; } = {
    pre_assessment: [`${routePrefix}.careplan`, `${routePrefix}.timeline`],
    not_admitted: [`${routePrefix}.supportNetwork`, `${routePrefix}.careplan`, `${routePrefix}.timeline`],
    enrolled: [`${routePrefix}.timeline`],
    emergency_admission: [`${routePrefix}.supportNetwork`, `${routePrefix}.careplan`, `${routePrefix}.assessments`, `${routePrefix}.profile`],
    enquiry: [`${routePrefix}.supportNetwork`, `${routePrefix}.careplan`],
  };

  const dynamicLinks = computed(() => {
    if (!state.value) return [];
    if (defaultLinks.length === 0) return defaultLinks;
    const linksToBeHidden = new Set<string>();
    if (isNonAssessmentType(clientType.value)) {
      linksToBeHidden.add(`${routePrefix}.assessments`);
    }
    if (!userHasPermission('readCareplan')) {
      linksToBeHidden.add(`${routePrefix}.careplan`);
    }
    if (window.familyPortal) {
      linksToBeHidden.add(`${routePrefix}.assessments`);
      linksToBeHidden.add(`${routePrefix}.alarms`);
      linksToBeHidden.add(`${routePrefix}.supportNetwork`);
    }
    if (!(userCan('any', 'londonCareRecord'))) {
      linksToBeHidden.add(`${routePrefix}.medicalRecords`);
    }
    if (hiddenLinksByState[state.value]) {
      hiddenLinksByState[state.value].forEach(linksToBeHidden.add, linksToBeHidden);
    }
    return filterLinksWithRouteNames(defaultLinks, Array.from(linksToBeHidden));
  });

  function isNonAssessmentType(type: string) {
    return ['service_management', 'care_template', 'animal'].includes(type);
  }

  function getDefaultLinks(): TopBarLink[] {
    const baseLinks = [{
      name: translate('person.profile'),
      icon: 'nr-icon-profile',
      to: { name: `${routePrefix}.profile`, query: { ...stateQuery() } },
    },
    {
      name: translate('client.sections.support_network'),
      icon: 'nr-icon-circle-care',
      to: { name: `${routePrefix}.supportNetwork`, query: { Archived: 'false', ...stateQuery() } },
    },

    {
      name: translate('person.timeline'),
      icon: 'nr-icon-watch',
      to: { name: `${routePrefix}.timeline`, query: { mine: 'false', ...stateQuery() } },
      activeOnChildren: activateTimeLine,
    },
    {
      name: translate('client.care_plan'),
      icon: 'nr-icon-care-plan',
      to: { name: forFamilyPortal ? `${routePrefix}.careplanReport` : `${routePrefix}.careplan`, query: { ...stateQuery() } },
    },
    {
      name: translate('client.sections.medical_records'),
      icon: 'nr-icon-new-folder',
      to: { name: `${routePrefix}.medicalRecords`, query: { ...stateQuery() } },
    },
    {
      name: translate('client.alarm', 2),
      icon: 'nr-icon-bell',
      to: { name: `${routePrefix}.alarms`, query: { ...stateQuery() } },
    },
    ];
    const assessmentsLink = {
      name: translate('client.sections.assessments'),
      icon: 'nr-icon-assessments',
      to: { name: `${routePrefix}.assessments`, query: { ...stateQuery() } },
    };
    if (forMobileView && !forFamilyPortal) {
      baseLinks.push({
        name: translate('navigation.other.dashboard'),
        icon: 'nr-icon-profile',
        to: { name: `${routePrefix}.dashboard`, query: { ...stateQuery() } },
      });
    }

    if (!(useUserStore().getFeatureToggle('londonCareRecord'))) {
      const medicalIndex = baseLinks.findIndex((link) => link.to.name === 'client.medicalRecords');
      if (medicalIndex >= 0) {
        baseLinks.splice(medicalIndex, 1);
      }
    }

    if (useUserStore().getFeatureToggle('assessments')) {
      const supportNetworkIndex = baseLinks.findIndex((link) => link.to.name === `${routePrefix}.supportNetwork`);
      if (supportNetworkIndex >= 0) {
        baseLinks.splice(supportNetworkIndex + 1, 0, assessmentsLink);
      }
    }
    return baseLinks;
  }

  function stateQuery(): LocationQuery {
    if (route.query.state) return { state: route.query.state };
    return {};
  }
  const timelineNestedRoute = ['client.timeline.interaction_new',
    'client.timeline.interaction',
    'client.timeline.needinteraction',
    'client.timeline.service_selector',
    'client.timeline.interaction_new.documents',
    'client.timeline.interaction.documents',
    'client.timeline.interaction.cancel',
    'client.timeline.interaction.snooze',
  ];

  return {
    clientStore,
    defaultLinks,
    dynamicLinks,
    route,
  };
}
