import { computed } from 'vue';
import useMessagesStore from '@/messages/store/messagesStore';
import use from '@/_shared/compositionApi';
import { TopBarLink } from '@/_shared/types/baseTopBarLink';
import inbox from '@/_shared/assets/images/messages/mail.svg';
import sent from '@/_shared/assets/images/messages/send.svg';
import archivedInbox from '@/_shared/assets/images/messages/inbox_archived.svg';
import archivedSent from '@/_shared/assets/images/messages/sent_archived.svg';
import { storeToRefs } from 'pinia';

export default function getMessagesLinks(): TopBarLink[] {
  const { translate } = use.helpers();
  const { currentFolder, newMessage } = storeToRefs(useMessagesStore());
  return [
    {
      name: translate('message.folders.inbox'),
      icon: inbox,
      to: { path: '/messages', force: true },
      customizedActiveMatcher: true,
      activeOnChildren: computed(() => !newMessage.value && currentFolder.value === 'inbox'),
    },
    {
      name: translate('message.folders.sent'),
      icon: sent,
      to: { path: '/messages/sent', force: true },
      customizedActiveMatcher: true,
      activeOnChildren: computed(() => !newMessage.value && currentFolder.value === 'sent'),
    },
    {
      name: translate('message.folders.archived_inbox'),
      icon: archivedInbox,
      to: { path: '/messages/archived_inbox', force: true },
      customizedActiveMatcher: true,
      activeOnChildren: computed(() => !newMessage.value && currentFolder.value === 'archived_inbox'),
    },
    {
      name: translate('message.folders.archived_sent'),
      icon: archivedSent,
      to: { path: '/messages/archived_sent', force: true },
      customizedActiveMatcher: true,
      activeOnChildren: computed(() => !newMessage.value && currentFolder.value === 'archived_sent'),
    },
  ];
}
