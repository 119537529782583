import { SkinInstance, LocationDetail, InstanceLocations } from '@/_shared/types/NourishInstance';
import {
  computed, ComputedRef, ref, Ref,
} from 'vue';
import { useBodyMapLocationStore } from '@/_shared/store/bodyMapLocations';
import use from '@/_shared/compositionApi';

const { translate } = use.helpers();

const useBodyMapLocationsHelper = (showArchived = ref(true)) => {
  let skinInstances: ComputedRef<SkinInstance[]> | Ref<SkinInstance[]>;
  const sortByStateTime = (a: SkinInstance, b: SkinInstance) => (a.stateTime < b.stateTime ? 1 : -1);

  // TODO use filtered instances
  const allInstances = computed(() => {
    const newInstances = skinInstances.value
      .filter((skinInstance) => skinInstance.state === 'new')
      .sort(sortByStateTime);
    const activeInstances = skinInstances.value
      .filter((skinInstance) => skinInstance.state === 'active')
      .sort(sortByStateTime);
    const archivedInstances = skinInstances.value
      .filter((skinInstance) => skinInstance.state === 'archived')
      .sort(sortByStateTime);

    return [
      { state: translate('body_map.state.new'), instances: newInstances },
      { state: translate('body_map.state.active'), instances: activeInstances },
      { state: translate('body_map.state.archived'), instances: archivedInstances },
    ];
  });

  const multiLocations: string[] = [];
  const instanceLocations: ComputedRef<InstanceLocations> = computed(() => getInstanceLocations(skinInstances.value));

  const getInstanceLocations = (instances: SkinInstance[]) => {
    const uniqueLocations = getUniqueLocations(instances);
    const frontLocations: LocationDetail[] = [];
    const backLocations: LocationDetail[] = [];
    uniqueLocations.forEach((instance) => {
      const primaryLocation = createLocation(instance);
      if (!primaryLocation) return;
      if (instance.locationInfo?.side === 'back') {
        backLocations.push(primaryLocation!);
      } else {
        frontLocations.push(primaryLocation!);
      }
      if (instance.locationInfo?.side !== 'front') {
        const secondaryLocation = createLocation(instance, false);
        if (instance.locationInfo?.side === 'back') {
          frontLocations.push(secondaryLocation!);
        } else {
          backLocations.push(secondaryLocation!);
        }
      }
    });
    return {
      frontLocations,
      backLocations,
    };
  };

  const getUniqueLocations = (instances: SkinInstance[]) => {
    const uniqueLocations: SkinInstance[] = [];
    instances.forEach((instance) => {
      if (!instance.locationInfo || !instance.locationInfo.position || (!showArchived.value && instance.state === 'archived')) {
        return;
      }
      if (
        uniqueLocations.filter((uniqueInstance) => uniqueInstance.locationInfo?.codename === instance.locationInfo?.codename).length > 0
      ) {
        multiLocations.push(instance.locationInfo.codename);
      } else {
        uniqueLocations.push(instance);
      }
    });
    return uniqueLocations;
  };

  const createLocation = (instance: SkinInstance, primary = true) => {
    if (instance.locationInfo) {
      return {
        codename: instance.locationInfo.codename,
        x: primary ? instance.locationInfo.position.x : instance.locationInfo.secondary.x,
        y: primary ? instance.locationInfo.position.y : instance.locationInfo.secondary.y,
        multi: multiLocations.includes(instance.locationInfo.codename),
        side: instance.locationInfo.side,
        large: instance.locationInfo.large,
        count: instance.locationInfo.count,
      } as LocationDetail;
    }
    return null;
  };

  const defaultSide = computed(() => {
    if (skinInstances.value.length > 0 && skinInstances.value.every((instance) => instance.locationInfo?.side === 'back')) {
      return 'back';
    }
    return 'all';
  });
  const helpersFunc = {
    defaultSide,
    instanceLocations,
    allInstances,
    getInstanceLocations,
  };
  function forSkinInstances(instances: ComputedRef<SkinInstance[]> | Ref<SkinInstance[]>) {
    skinInstances = computed(() => (instances.value));
    return helpersFunc;
  }
  function forLocationCodeName(location: ComputedRef<string|undefined>) {
    const { getLocationInfo } = useBodyMapLocationStore();
    skinInstances = computed(() => {
      if (location.value) {
        const locationInfo = getLocationInfo((location.value as string)).value;
        const instance = {
          state: 'active',
          metadata: {
            location: location.value,
            specificType: '',
          },
        } as SkinInstance;
        return locationInfo ? [{ ...instance, locationInfo }] : [instance];
      }
      return [] as SkinInstance[];
    });
    return helpersFunc;
  }
  return {
    forSkinInstances,
    forLocationCodeName,
  };
};

export default useBodyMapLocationsHelper;
