import apiClient from '@/_shared/services/apiClient';
import { BodyMapDefaultLocations, SkinInstance } from '@/_shared/types/NourishInstance';

type SkinInstanceApiResponse = {
  nourishInstances: SkinInstance[]
}

export type ClientSkinInstances = {
  id: number;
  skinInstances: SkinInstance[];
}

type BodyMapLocationsApiResponse = {
  bodyMapDefaultLocations: BodyMapDefaultLocations
}
export const fetchSkinInstances = async (clientId: number): Promise<ClientSkinInstances> => {
  const compareInstances = (a: SkinInstance, b: SkinInstance) => {
    const stateToValue: Record<string, number> = {
      new: 1,
      active: 2,
      archived: 3,
    };

    const getStateValue = (state: string) => {
      if (stateToValue[state]) return stateToValue[state];
      return 4;
    };

    const stateDifference = getStateValue(a.state) - getStateValue(b.state);
    const timeDifference = (a.stateTime < b.stateTime ? 1 : -1);
    return stateDifference === 0 ? timeDifference : stateDifference;
  };
  const addCountToInstances = (instances: SkinInstance[]) => {
    // TODO: move adding count to backend
    const locationToCount: Record<string, number> = {};

    instances.forEach((instance) => {
      if (instance.locationInfo) {
        let instancePositionKey = '';
        if (instance.locationInfo.position) instancePositionKey = instance.locationInfo.position.x.toString() + instance.locationInfo.position.y.toString();
        if (!(instancePositionKey in locationToCount)) locationToCount[instancePositionKey] = Object.keys(locationToCount).length + 1;
        instance.locationInfo.count = locationToCount[instancePositionKey];
      } else {
        instance.locationInfo = {} as SkinInstance['locationInfo'];
      }
    });
  };

  const url = `/api/v2/clients/${clientId}/nourish_instances/skin`; // TODO use other endpoint for now
  try {
    return apiClient.get<SkinInstanceApiResponse>(url, { params: { include_location_info: true } }).then((result: SkinInstanceApiResponse) => {
      const instances = result.nourishInstances;
      instances.sort(compareInstances);
      addCountToInstances(instances);
      return { id: clientId, skinInstances: instances };
    });
  } catch (errorsResponse) {
    return { id: clientId, skinInstances: [] as SkinInstance[] };
  }
};
export const fetchBodyMapDefaultLocations = async (): Promise<BodyMapDefaultLocations> => {
  const url = '/api/v2/body_map_locations'; // TODO use other endpoint for now
  try {
    return (await apiClient.get<BodyMapLocationsApiResponse>(url)).bodyMapDefaultLocations;
  } catch (errorsResponse) {
    return {} as BodyMapDefaultLocations;
  }
};
export const fetchInstancesById = async (instanceIds: number[]): Promise<SkinInstance[]> => {
  const url = '/api/v2/nourish_instances/query';
  try {
    return (await apiClient.post<SkinInstanceApiResponse>(url, { ids: instanceIds })).nourishInstances;
  } catch (errorsResponse) {
    return [] as SkinInstance[];
  }
};
