import use from '@/_shared/compositionApi';
import { TopBarLink } from '@/_shared/types/baseTopBarLink';
import { LocationQuery, RouteLocationNormalizedLoaded } from 'vue-router';

export default function getDefaultLinks(route: RouteLocationNormalizedLoaded): TopBarLink[] {
  const { translate } = use.helpers();

  function stateQuery():LocationQuery {
    if (route && route.query && route.query.state) return { state: route.query.state };
    return {};
  }

  const defaultLinks:TopBarLink[] = [{
    name: translate('person.profile'),
    icon: 'nr-icon-profile',
    to: { name: 'carer.profile', query: { ...stateQuery() } },
  },
  {
    name: translate('person.timeline'),
    icon: 'nr-icon-watch',
    to: { name: 'carer.timeline', query: { ...stateQuery() } },
  },
  {
    name: translate('carer.sections.additional_info'),
    icon: 'nr-icon-report',
    to: { name: 'carer.certification', query: { ...stateQuery() } },
  },
  ];

  return defaultLinks;
}
