import CollectionStore from '@/_shared/store/collectionStore';
import { SkinInstance } from '@/_shared/types/NourishInstance';
import { fetchInstancesById, fetchSkinInstances } from '@/_shared/services/nourishInstancesApi';

type SkinInstanceType = {
  id: number;
  skinInstances: SkinInstance;
}

export default class InstanceStore extends CollectionStore<SkinInstance, SkinInstanceType[]> {
  private fetchedClientIds = new Set<number>();

  fetch(instanceIds: number[]): Promise<SkinInstanceType[]> {
    const searchIds: number[] = instanceIds.filter((id) => id);
    return fetchInstancesById(searchIds).then((matchingInstances) => {
      const skinInstances: SkinInstanceType[] = [];
      matchingInstances.forEach((instance) => {
        skinInstances.push({ id: instance.id, skinInstances: instance });
      });
      return skinInstances;
    });
  }

  extractData(data: SkinInstanceType[]): SkinInstance[] {
    const skinInstances: SkinInstance[] = [];
    data.forEach((instance) => {
      skinInstances.push(instance.skinInstances);
    });
    return skinInstances;
  }

  byClientId(clientId: number): Promise<SkinInstance[]> {
    if (this.fetchedClientIds.has(clientId)) {
      return Promise.resolve(Object.values(this.collection).filter((instance) => instance.clientId === clientId));
    }
    return fetchSkinInstances(clientId).then((response) => {
      const instances = response.skinInstances;
      instances.forEach((instance) => {
        this.upsert(instance);
      });
      this.fetchedClientIds.add(clientId);
      return instances;
    });
  }
}

export const instanceStoreSingleton: InstanceStore = new InstanceStore();
