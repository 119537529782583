export const instanceCodenames = {
  skin: [
    'skin_pressure_injury',
    'skin_ulcer_instance',
    'skin_tear',
    'skin_burn',
    'skin_laceration',
    'skin_bruise',
    'skin_wounds',
    'skin_surgical_wound',
    'skin_superficial_wound',
    'skin_moisture_lesion',
    'skin_other',
    'skin_unspecified',
  ],
};

export const otherInstanceCodenames = {
  skin: 'skin_other',
};

export type InstanceCodenames = typeof instanceCodenames;

export type NourishInstanceType = keyof InstanceCodenames;
