
import { defineComponent, PropType } from 'vue';
import { format } from 'date-fns';
import use from '@/_shared/compositionApi';
import BaseCard from '@/_shared/components/display/BaseCard.vue';
import { CalendarEvent, GpConnectResponse, Sections } from '../types/gp_connect';
import GpConnectPatientDetails from './GpConnectPatientDetails.vue';
import GpConnectDateFilter from './GpConnectDateFilter.vue';

export default defineComponent({
  components: {
    BaseCard,
    GpConnectPatientDetails,
    GpConnectDateFilter,
  },
  props: {
    response: {
      type: Object as () => GpConnectResponse,
      required: true,
    },
    section: {
      type: String as PropType<keyof typeof Sections>,
      required: true,
    },
    showDateFilter: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { translate } = use.helpers();
    const pageName = translate(Sections[props.section].pageNameKey);

    function formatPageLoaded(date: string) {
      return format(Date.parse(date), 'h:mm a - dd/MM/yyyy');
    }

    function calendarEvent(data: CalendarEvent) {
      emit('calendarEvent', data);
    }

    return {
      translate,
      formatPageLoaded,
      calendarEvent,
      pageName,
    };
  },
});
