
import { defineComponent } from 'vue';
import use from '@/_shared/compositionApi';
import BaseCard from '@/_shared/components/display/BaseCard.vue';

export default defineComponent({
  components: {
    BaseCard,
  },
  setup() {
    const { translate } = use.helpers();
    return {
      translate,
    };
  },
});
