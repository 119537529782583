import CollectionStore from '@/_shared/store/collectionStore';
import { ClientSkinInstances, fetchSkinInstances } from '@/_shared/services/nourishInstancesApi';

export default class InstancesStore extends CollectionStore<ClientSkinInstances, ClientSkinInstances[]> {
  fetch(ids: number[]): Promise<ClientSkinInstances[]> {
    // todo this temporary no endpoint to get multiple clients skin instances, we may not even needed but it's as safe guard
    return Promise.all(ids.map((id) => fetchSkinInstances(id)));
  }

  extractData(data: ClientSkinInstances[]): ClientSkinInstances[] {
    return data;
  }
}

export const instancesStore: InstancesStore = new InstancesStore();
