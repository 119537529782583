import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { Datasets, fetchAllDatasets } from '@/_shared/services/datasetsApi';
import useBaseStore from '@/_shared/store/base';
import { Dataset } from '@/timeline/types/Dataset';
import { valueToCamel } from '@/_shared/services/keysToCamel';
import valueToSnake from '@/_shared/services/keysToSnake';
import {
  DATA_SET_SKIN_BODYMAP_LOCATIONS,
  useBodyMapLocationStore,
} from '@/_shared/store/bodyMapLocations';
import { until } from '@/_shared/services/UseUtils';
import {
  instanceCodenames,
  NourishInstanceType,
} from '@/_shared/store/composables/datasetsInstances';

const useDatasetStore = defineStore('dataset', () => {
  const datasets = ref<Datasets>({});
  const bodyMapLocationStore = storeToRefs(useBodyMapLocationStore());
  const { initialize, initialized } = useBaseStore();
  const initialLoad = async () => {
    datasets.value = await fetchAllDatasets();
    // todo remove this when skin-bodymap-locations is comming from data set api
    await until(() => bodyMapLocationStore.initialized.value);
  };

  initialize(initialLoad);

  const getDatasets = (codenames: string[]): Datasets => {
    if (codenames[0] === DATA_SET_SKIN_BODYMAP_LOCATIONS) {
      return {
        dataSetWoundLocation: {
          items: bodyMapLocationStore.allLocationsCodeNames.value || {}, favourites: [], translatable: true, name: 'wound_location',
        },
      };
    }
    const cns = codenames.filter((cn) => cn).map((cn) => valueToCamel(cn));
    return Object.fromEntries(
      Object.entries(datasets.value).filter(([key]) => cns.includes(key)),
    );
  };

  const getDataset = (codename: string) => computed((): Dataset => Object.values(getDatasets([codename]))[0]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const nourishInstanceTypeDataSets = (nourishInstanceType: NourishInstanceType) => instanceCodenames[nourishInstanceType]
    ?.map((datasetCodename) => `data-set-${datasetCodename}`);

  const getWoundTypeIndex = (nourishInstanceType: NourishInstanceType) => computed(() => {
    const woundTypesLookup: Record<string, string> = {};
    const nourishInstanceTypeDS = computed(() => getDatasets(nourishInstanceTypeDataSets(nourishInstanceType))).value;
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(nourishInstanceTypeDS)) {
      value.items.forEach((item) => { woundTypesLookup[item] = valueToSnake(key.replace('dataSet', '')); });
    }
    return woundTypesLookup;
  });

  return {
    datasets,
    getDatasets,
    getDataset,
    initialized,
    nourishInstanceTypeDataSets,
    getWoundTypeIndex,
  };
});

export default useDatasetStore;
