import { computed, Ref, ref } from 'vue';
import { filledParametersPresent } from '@/timeline/helper/interactionsHelper';
import { Parameter } from '@/timeline/types/Parameter';
import IInteraction from '@/timeline/types/IInteraction';

const useInteractionListEntryParameters = (interaction: Ref<IInteraction>) => {
  const showParameters = ref(false);

  const showParameterButton = computed(() => hasFilledParameters.value || hasPerformedComputedParameterValues.value);
  const showNoteButton = computed(() => interaction.value.notePublic && interaction.value.notePublic.length > 0);

  const hasFilledParameters = computed(() => filledParametersPresent(interaction.value, false));
  // TODO: when move over rest of interaction card, add '&& !props.assessments' to above line
  // Or pass in as a prop to make generic

  const sortedParameters = computed(() => {
    let paramToDisplay = interaction.value.parameters;
    if (!paramToDisplay) { return []; }
    paramToDisplay = paramToDisplay.filter((p: Parameter) => {
      if (p.valueType !== 'read_only_text') {
        const { coercedValue } = p;
        if (coercedValue !== null) {
          if (typeof coercedValue === 'object' && 'values' in coercedValue) {
            return coercedValue.values.length > 0;
          }
          if (typeof coercedValue === 'object' && 'value' in coercedValue) {
            return coercedValue.value !== null;
          }
          if (typeof coercedValue === 'object' && 'metadata' in coercedValue) {
            return coercedValue.metadata.location !== null && coercedValue.metadata.specificType !== null;
          }
          return true;
        }
        if (p.valueType === 'file') {
          return p.coercedValue !== null;
        }
      }
      return false;
    });
    return [...paramToDisplay].sort((a, b) => (a.position - b.position));
  });

  const computedParameters = ref<Parameter[]>(interaction.value?.computedParameters?.filter((parameter: Parameter) => parameter.id !== 'actions') || []);
  const hasPerformedComputedParameterValues = computed(() => interaction.value.state !== 'planned' && computedParameters.value?.length > 0 && interaction.value.savedAt);
  // TODO: when move over rest of interaction card, add '&& !props.assessments' to above line
  // Or pass in as a prop to make generic

  return {
    showParameters,
    showParameterButton,
    showNoteButton,
    computedParameters,
    sortedParameters,
    hasFilledParameters,
    hasPerformedComputedParameterValues,
  };
};

export default useInteractionListEntryParameters;
