import { defineStore } from 'pinia';
import useBaseStore from '@/_shared/store/base';

import { computed, ComputedRef, ref } from 'vue';
import {
  BodyMapDefaultLocations, FullCoordinates, InstanceLocations,
  LocationDetail,
  LocationInfo, SectionLocationsAtSide,
  SkinInstance,
} from '@/_shared/types/NourishInstance';
import { fetchBodyMapDefaultLocations } from '@/_shared/services/nourishInstancesApi';
import valueToSnake from '@/_shared/services/keysToSnake';
import { valueToCamel } from '@/_shared/services/keysToCamel';
import { useSingletonComputed } from '@/_shared/services/UseUtils';

export const useBodyMapLocationStore = defineStore('bodyMapLocations', () => {
  const { initialize, initialized } = useBaseStore();
  const bodyMapDefaultLocations = ref({} as BodyMapDefaultLocations);

  const initialLoad = async () => {
    bodyMapDefaultLocations.value = await fetchBodyMapDefaultLocations();
  };

  initialize(initialLoad);

  const allLocationsCodeNames = computed(() => {
    let allCodenames : string[] = [];
    ['front', 'back'].forEach((side) => {
      allCodenames = [...new Set([...allCodenames, ...(locationsCodeNamesOnSide(side).value)])];
    });
    return allCodenames;
  });

  const locationsCodeNamesOnSide = useSingletonComputed<string, string[]>((side: string) => {
    if (side === 'all') return allLocationsCodeNames.value;
    return Object.keys(allLocationsOnSide(side).value || {}).map(valueToSnake);
  });

  const allLocationsOnSide = useSingletonComputed<string, Record<string, LocationInfo> >((side: string) => ({
    ...bodyMapDefaultLocations.value.bothLocations,
    ...bodyMapDefaultLocations.value[`${side}Locations` as keyof BodyMapDefaultLocations] as Record<string, LocationInfo>,
  }));

  const sectionLocationsCodeNamesAtSide = (sectionCodename: string, side: string): SectionLocationsAtSide => {
    if (side === 'front') return locationsCodeNamesInSectionAtSide(sectionCodename, 'front');
    if (side === 'back') return locationsCodeNamesInSectionAtSide(sectionCodename, 'back');
    const allFront: SectionLocationsAtSide = locationsCodeNamesInSectionAtSide(sectionCodename, 'front');
    const allSectionLocations = [...new Set([
      ...allFront.codeNames,
      ...locationsCodeNamesInSectionAtSide(sectionCodename, 'back').codeNames,
    ]),
    ];
    return {
      codeNames: allSectionLocations,
      defaultLocationCodeName: allFront.defaultLocationCodeName,
    };
  };

  const locationsCodeNamesInSectionAtSide = (sectionCodename: string, side: string): SectionLocationsAtSide => {
    const sectionCn = Object.values(allLocationsOnSide(side).value || {})
      .find((location) => location.locationCodename === sectionCodename)?.section;
    const locations = !sectionCn ? [] : (Object.entries(allLocationsOnSide(side).value || {}))
      .filter(([, location] : [string, LocationInfo ]) => location.section === sectionCn);
    const sectionDefaultLocationCn = locations.find(([, location] : [string, LocationInfo ]) => location.sectionDefault);
    return {
      codeNames: locations.map(([key]: [string, LocationInfo]) => valueToSnake(key)),
      defaultLocationCodeName: sectionDefaultLocationCn && sectionDefaultLocationCn?.length > 0 ? valueToSnake(sectionDefaultLocationCn[0]) : '',
    };
  };

  const bodySectionsLocations: ComputedRef<InstanceLocations> = computed(() => {
    const result = {
      frontLocations: [] as LocationDetail[],
      backLocations: [] as LocationDetail[],
    };
    if (Object.keys(bodyMapDefaultLocations.value.bothLocations || {}).length === 0) return result;
    const sections = Object.values(bodyMapDefaultLocations.value.bothLocations)
      .filter((location) => location.sectionDefault && location.locationCodename !== 'whole_body')
      .map((location) => getLocationsDetails(location.locationCodename, true));
    result.frontLocations = sections.map(([front]) => front);
    const pubisSection = getLocationsDetails(bodyMapDefaultLocations.value.frontLocations.pubis.locationCodename, true);
    result.frontLocations.push(pubisSection[0]);
    result.backLocations = sections.map(([, back]) => back);
    return result;
  });
  const getLocationsDetails = (codename: string, section = false): LocationDetail[] => {
    const coord: FullCoordinates = bodyMapDefaultLocations.value.locationCoordinates[valueToCamel(codename)];
    let primaryLocation = {
      codename,
      x: coord.position.x,
      y: coord.position.y,
      multi: false,
      side: 'front',
      large: !!coord.large,
      count: 0,
    };
    let secondaryLocation = {
      codename,
      x: coord.secondary.x,
      y: coord.secondary.y,
      multi: false,
      side: 'back',
      large: !!coord.large,
      count: 0,
    };
    if (section) {
      primaryLocation = {
        ...primaryLocation,
        ...{
          x: coord.sectionPosition!.x,
          y: coord.sectionPosition!.y,
          x2: coord.sectionPosition!.x2,
          y2: coord.sectionPosition!.y2,
        },
      };
      secondaryLocation = {
        ...secondaryLocation,
        ...{
          x: coord.sectionSecondary?.x || coord.secondary.x,
          y: coord.sectionSecondary?.y || coord.secondary.y,
          x2: coord.sectionSecondary?.x2 || coord.position.x2,
          y2: coord.sectionSecondary?.y2 || coord.position.y2,
        },
      };
    }
    return [primaryLocation, secondaryLocation];
  };

  const getLocationInfo = (codename: string, count = 0) => computed(() => {
    const cnCamelCase = valueToCamel(codename);
    const locationDetails = getLocation();
    if (!locationDetails) return undefined;
    const { locationCodename } = locationDetails[1] as LocationInfo;
    const location = bodyMapDefaultLocations.value.locationCoordinates[valueToCamel(locationCodename)];
    if (!location) return undefined;
    return {
      ...location,
      codename: locationCodename,
      side: locationDetails[0],
      count,
    } as SkinInstance['locationInfo'];

    function getLocation() {
      if (!initialized.value) return undefined;
      if (bodyMapDefaultLocations.value.bothLocations[cnCamelCase]) {
        return ['both', bodyMapDefaultLocations.value.bothLocations[cnCamelCase]];
      }
      if (bodyMapDefaultLocations.value.frontLocations[cnCamelCase]) {
        return ['front', bodyMapDefaultLocations.value.frontLocations[cnCamelCase]];
      }
      if (bodyMapDefaultLocations.value.backLocations[cnCamelCase]) {
        return ['back', bodyMapDefaultLocations.value.backLocations[cnCamelCase]];
      }
      return undefined;
    }
  });

  return {
    initialized,
    bodyMapDefaultLocations,
    sectionLocationsCodeNamesAtSide,
    locationsCodeNamesOnSide,
    allLocationsCodeNames,
    bodySectionsLocations,
    getLocationInfo,
  };
});
export const DATA_SET_SKIN_BODYMAP_LOCATIONS = 'data-set-skin-bodymap-locations';
