import i18n from '@/_shared/translations/i18n';
import useUserAuthStore from '@/_shared/store/userAuth';
import { storeToRefs } from 'pinia';
import { toast, ToastType } from './nourishHelpers';
import LoadingStatus from './loadingStatus';
import keysToCamel from './keysToCamel';

const translate = i18n.global.t;

// TODO split into smaller files ???

export interface ApiRequestConfig<T = unknown, C = unknown> {
    url?: string;
    headers?: { common?: C};
    params?: unknown; // TODO implement query params serializer
    data?: T;
    rootNode?: string;
}

export interface ApiResponse<T> {
    data: T;
    status: number;
    statusText: string;
}

export type ApiNestedResponse<E, T> = {
  [key in E as string]: T;
}

export class ApiError<T = unknown> extends Error {
  constructor(mesage?: string, public code?: string, public response?: ApiResponse<T>) {
    super(mesage);
  }
}
const apiSpinner = new LoadingStatus();

// TODO see if we can type the response.data
function buildErrorMessage(response: ApiResponse<Record<string, unknown>>) {
  if (response.data) {
    if (response.data.message) {
      return response.data.message as string;
    }
    if (response.data.message_tag) {
      return translate(response.data.message_tag as string);
    }
  }
  if (response.status === 403) {
    return translate('ajax.errors.permission');
  }
  if (response.status === 400 || response.status === 500) {
    return translate('ajax.errors.server');
  }
  if (response.status === 504) {
    return translate('ajax.errors.timeout');
  }
  return translate('ajax.errors.not_saved') as string;
}

// TODO SHOULD THESE REALLY DO THE TOAST?
function handleResponseError(response: ApiResponse<Record<string, unknown>>) {
  const message = buildErrorMessage(response);
  toast(message, ToastType.Danger);
}

function handleError(error: ApiError) {
  if (isMissingTokenError(error)) { return; }
  const message = error.response ? buildErrorMessage(error.response as ApiResponse<Record<string, unknown>>) : translate('ajax.errors.server');
  toast(message, ToastType.Danger);
}

// while we are still in Angular, do not display missing token error toasts
// TODO remove in standalone Vue
function isMissingTokenError(error: ApiError) {
  // TODO handle typing better, perhaps return special code from endpoint?
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return error.response && (error.response as any).data?.error === 'headers.missing-token';
}

// refactored into standalone function for fine grained status check, if needed in the future
function isErrorStatus(status: number) {
  return status >= 400;
}

function isSuccessStatus(status: number) { return !isErrorStatus(status); }

export abstract class ApiClient {
  protected baseUrl = '/api/v3/';

  endpointUrl(endpoint: string): string {
    if (endpoint.includes('/api/v')) {
      return endpoint;
    }
    return this.baseUrl + endpoint;
  }

  // TODO needs testing with standalone app
  addAuthHeaders(config: ApiRequestConfig<Record<string, unknown>, Record<string, unknown>>) {
    const authStore = storeToRefs(useUserAuthStore());

    if (window.currentOrganisationUnit) this.addHeader(config, 'Organisation-Unit-id', `${window.currentOrganisationUnit.id}`);

    if (authStore.token.value && authStore.ouId.value) {
      this.addHeader(config, 'x-api-key', authStore.token.value);
      this.addHeader(config, 'Organisation-Unit-Id', authStore.ouId?.value.toString());
    }

    // This is not a hack and should be sent with every request to ensure security
    const value: null | string = window.localStorage.getItem('deviceUUID');
    if (value) {
      this.addHeader(config, 'X-Device-Uuid', value);
    }
  }

  addHeader(config: ApiRequestConfig<Record<string, unknown>, Record<string, unknown>>, key: string, value: string) {
    if (!config.headers) {
      config.headers = {};
    }
    if (!config.headers.common) {
      config.headers.common = {};
    }
    config.headers.common[key] = value;
  }

  async get<R>(urlPath: string, config?: ApiRequestConfig) {
    const url = this.endpointUrl(urlPath);
    return this.handleResponse(this.internalGet<R>(url, config), config?.rootNode);
  }

  abstract internalGet<R>(url: string, config?: ApiRequestConfig): Promise<ApiResponse<R>>;

  async post<R, D = Record<string, unknown>>(urlPath: string, data?: D, config?: ApiRequestConfig) {
    const url = this.endpointUrl(urlPath);
    return this.handleResponse(this.internalPost<R, D>(url, data, config), config?.rootNode);
  }

  abstract internalPost<R, D>(url: string, data?: D, config?: ApiRequestConfig): Promise<ApiResponse<R>>;

  async patch<R, D = Record<string, unknown>>(urlPath: string, data?: D, config?: ApiRequestConfig) {
    const url = this.endpointUrl(urlPath);
    return this.handleResponse(this.internalPatch<R, D>(url, data, config), config?.rootNode);
  }

  abstract internalPatch<R, D>(url: string, data?: D, config?: ApiRequestConfig): Promise<ApiResponse<R>>;

  async put<R>(urlPath: string, data?: Record<string, unknown>, config?: ApiRequestConfig) {
    const url = this.endpointUrl(urlPath);
    return this.handleResponse(this.internalPut<R>(url, data, config), config?.rootNode);
  }

  abstract internalPut<R>(url: string, data?: Record<string, unknown>, config?: ApiRequestConfig): Promise<ApiResponse<R>>;

  async delete<R>(urlPath: string, config?: ApiRequestConfig) {
    const url = this.endpointUrl(urlPath);
    return this.handleResponse(this.internalDelete<R>(url), config?.rootNode);
  }

  abstract internalDelete<R>(url: string, config?: ApiRequestConfig): Promise<ApiResponse<R>>;

  async handleResponse<R>(apiPromise: Promise<ApiResponse<R>>, rootNode?: string): Promise<R> {
    const resp = await apiPromise;
    if (isSuccessStatus(resp.status)) {
      const data = resp.data as Record<string, unknown | R>;
      return rootNode ? data[rootNode] as R : data as R;
    }
    throw new ApiError(resp.statusText, resp.status.toString(), resp);
  }

  constructor() {
    this.setupInterceptors(this.onRequest, this.onResponse, this.onResponseError);
  }

  abstract setupInterceptors(
      onRequest:(config: ApiRequestConfig<Record<string, unknown>, Record<string, unknown>>) => ApiRequestConfig,
      onResponse:(resp: ApiResponse<Record<string, unknown>>) => void,
      onResponseError:(error: ApiError) => ApiResponse<unknown> | ApiError
  ): void;

  // these must remain arrow functions otherwise a reference to 'this' is lost
  onRequest = (config: ApiRequestConfig<Record<string, unknown>, Record<string, unknown>>) => {
    // uncomment to troubleshoot unexpected API calls during testing
    // console.log('******** api client request ********');
    // console.log(config);

    apiSpinner.requestStarted();
    this.addAuthHeaders(config);
    return config;
  };

  onResponse = (resp: ApiResponse<Record<string, unknown>>) => {
    apiSpinner.requestFinished();
    // TODO do we need it? is not handled by onResponseError
    // this cause the message to be shown twice
    if (isErrorStatus(resp.status)) {
      handleResponseError(resp);
    }
    const camelisedResp = resp;
    camelisedResp.data = keysToCamel(resp.data) as Record<string, unknown>;
    return camelisedResp;
  };

  onResponseError = (error: ApiError) => {
    apiSpinner.requestFinished();
    handleError(error);
    return error.response ? error.response : error;
  };
}
