import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_AssignModalFilterableContent = _resolveComponent("AssignModalFilterableContent")!
  const _component_AssignModalContent = _resolveComponent("AssignModalContent")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    title: _ctx.title,
    footer: false,
    iconUrl: _ctx.iconUrl,
    icon: _ctx.icon,
    width: 800,
    onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    header: _withCtx(() => [
      (_ctx.filterable === true)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.assignButtonDisabled ? null : _ctx.$emit('openConfirmAssignEntries', _ctx.selectedToReassign, _ctx.selectedEntriesCount))),
            size: "3x",
            disabled: _ctx.assignButtonDisabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : (_openBlock(), _createBlock(_component_BaseButton, {
            key: 1,
            onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.assignButtonDisabled ? null : _ctx.$emit('openConfirmAssignEntries', _ctx.selectedEntries, _ctx.selectedEntriesCount))),
            size: "3x",
            disabled: _ctx.assignButtonDisabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          }, 8, ["disabled"]))
    ]),
    content: _withCtx(() => [
      (_ctx.filterable === true)
        ? (_openBlock(), _createBlock(_component_AssignModalFilterableContent, {
            key: 0,
            selectedToReassign: _ctx.selectedToReassign,
            "onUpdate:selectedToReassign": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedToReassign) = $event)),
            selectedEntriesCount: _ctx.selectedEntriesCount,
            "onUpdate:selectedEntriesCount": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedEntriesCount) = $event)),
            selectAllText: _ctx.selectAllText,
            selectDropdownOptions: _ctx.selectDropdownOptions,
            searchInputPlaceholder: _ctx.searchInputPlaceholder,
            selectableEntries: _ctx.selectableEntries as Client[],
            primaryTextKey: _ctx.primaryTextKey,
            secondaryTextKey: _ctx.secondaryTextKey
          }, null, 8, ["selectedToReassign", "selectedEntriesCount", "selectAllText", "selectDropdownOptions", "searchInputPlaceholder", "selectableEntries", "primaryTextKey", "secondaryTextKey"]))
        : (_ctx.selectedEntries)
          ? (_openBlock(), _createBlock(_component_AssignModalContent, {
              key: 1,
              selectedEntriesCount: _ctx.selectedEntriesCount,
              "onUpdate:selectedEntriesCount": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedEntriesCount) = $event)),
              selectAllText: _ctx.selectAllText,
              searchInputPlaceholder: _ctx.searchInputPlaceholder,
              selectableEntries: _ctx.selectableEntries as Carer[],
              primaryTextKey: _ctx.primaryTextKey,
              secondaryTextKey: _ctx.secondaryTextKey,
              selectedEntries: _ctx.selectedEntries
            }, null, 8, ["selectedEntriesCount", "selectAllText", "searchInputPlaceholder", "selectableEntries", "primaryTextKey", "secondaryTextKey", "selectedEntries"]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "iconUrl", "icon"]))
}