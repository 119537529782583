import { RouteLocationNamedRaw, RouteLocationRaw } from 'vue-router';
import { Ref } from 'vue';

export type TopBarLink = {
  name: string,
  icon: string,
  to: RouteLocationRaw,
  activeOnChildren?: Ref<boolean>,
  customizedActiveMatcher?: boolean,
}
export function filterLinksWithRouteNames(links: TopBarLink[], RouteNames: string[]) {
  return links.filter((link: TopBarLink) => !RouteNames.includes((link.to as RouteLocationNamedRaw).name as string));
}
